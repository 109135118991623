import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';  // Import useAuth for user authentication
import RecordButton from '../RecordButton';
import LoadingAlert from '../LoadingAlert';

const MockTestPart3Problems = ({ mocktestId }) => {
  const { user } = useAuth();  // Get user info from useAuth
  const [narratorSentence, setNarratorSentence] = useState(''); // New state for narrator sentence

  const [questions, setQuestions] = useState([]);
  const [timer, setTimer] = useState(3);  // Preparation timer
  const [recording, setRecording] = useState(false);
  const [isRecordingStarted, setIsRecordingStarted] = useState(false); // Track if recording has started
  const navigate = useNavigate();
  const { questionIndex } = useParams();  // Get questionIndex from URL params
  const mediaRecorderRef = useRef(null);  // Ref for the media recorder
  const streamRef = useRef(null);         // Ref for the media stream
  const timerRef = useRef(null);          // Ref for the timer
  const audioChunks = useRef([]);  // Store the recorded audio chunks
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch the narrator sentence and questions for Part 3
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mocktest${mocktestId}`)
      .then((response) => {
        setNarratorSentence(response.data.part3.narrator); // Fetch the narrator's sentence
        setQuestions(response.data.part3.questions);       // Fetch the questions
      })
      .catch((error) => console.error("Error fetching narrator and questions:", error));
  }, [mocktestId]);

  useEffect(() => {
    // Start the preparation countdown when a new question is displayed
      startPreparationCountdown();
    }
  , [questionIndex, questions]);


  const startPreparationCountdown = () => {
    clearInterval(timerRef.current);  // Clear any existing timers
    setTimer(3);  // Set the preparation timer

    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);  // Clear the interval when preparation ends
          startRecording();  // Start recording when the preparation time ends
        }
        return prev - 1;
      });
    }, 1000);  // Run the timer every 1 second
  };

  

  const startRecording = async () => {
    clearInterval(timerRef.current); // Clear the preparation timer
    setRecording(true);
    audioChunks.current = [];  // Clear any previous recordings

    // Start recording via microphone
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);  // Collect the audio chunks
        }
      };

      mediaRecorderRef.current.start();
      console.log("Microphone recording started");
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
    const answerTime = parseInt(questionIndex, 10) === 2 ? 30 : 15;
    // Start the answer countdown
    setTimer(answerTime); // Answer timer is also 45 seconds
    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          stopRecording();  // Stop recording when the answer time ends
        }
        return prev - 1;
      });
    }, 1000);
  };

  const stopRecording = () => {
    setRecording(false);

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();  // Stop the media recorder
      console.log("Microphone recording stopped");
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }

      mediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
          const formData = new FormData();
          formData.append('mocktest_id', mocktestId);
          formData.append('user_id', user.user_id);  // Get userId from useAuth
          formData.append('question_id', questions[questionIndex].question_id);
          formData.append('audio_file', audioBlob, `${questions[questionIndex].question_id}.wav`);
          formData.append('current_part', 3);  // For Part 3
          formData.append('reference_text', questions[questionIndex].text);  // Reference text

          try {
            setTimer(0);
            clearInterval(timerRef.current);
            setIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/submit-answer`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log('Answer submitted successfully:', response.data);
            if (response) {
              setIsLoading(false);

                if (parseInt(questionIndex, 10) < questions.length - 1) {
                  // Navigate to the next narrator
                  navigate(`/mocktest${mocktestId}/part3-narrator/${parseInt(questionIndex, 10) + 1}`);
                } else {
                  // Move to the next part if all questions are completed
                  navigate(`/mocktest${mocktestId}/part4-instruction`);
                }
              }
          } catch (error) {
            console.error('Error submitting answer:', error);
          }

      };
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-start">
      {isLoading && (
        <LoadingAlert 
          message="답변을 전송 중입니다. 잠시만 기다려주세요."
        />
      )}
      {questions.length > 0 && (
        <>
          {/* Display question index at the top with gray background */}
          <h1 className="text-xl lg:text-2xl font-bold text-center mb-6 bg-gray-300 w-full py-4">
            Questions {questions[questionIndex].question_id.split('_')[1].slice(1).replace(/^0+/, '')} of 11
          </h1>
  
          {/* Display narrator sentence above the question */}
          <div className="bg-white rounded-lg p-6 lg:p-8 w-full max-w-3xl mb-6">
            <p className="text-base md:text-lg lg:text-xl text-gray-700 mb-6">
              {narratorSentence}
            </p>
  
            {/* Display question text with line breaks preserved */}
            <p
  className="text-lg md:text-xl lg:text-2xl text-gray-700 mb-6"
  dangerouslySetInnerHTML={{
    __html: questions[questionIndex]?.text.replace(/\n/g, '<br />')
  }}
/>
          </div>
  
          <p className="text-lg lg:text-xl text-gray-800 mb-6 text-center">
            {recording ? "Recording Time" : "Preparation Time"}: {timer} seconds
          </p>
  
          <div className="flex justify-center">
            <RecordButton recording={recording} onStart={startRecording} onStop={stopRecording} />
          </div>
        </>
      )}
    </div>
  );
};

export default MockTestPart3Problems;