import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import useMixpanel from '../hooks/useMixpanel';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const Part3Description = () => {
  useMixpanel();
  const navigate = useNavigate();
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility
  const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/part3-practice-question.mp3`));  // Part 3 audio

  // Start the practice and stop the audio
  const startPractice = () => {
    if (audioRef.current) {
      audioRef.current.pause();  // Stop the audio
    }
    navigate('/practice-part3');  // Navigate to Part 3 practice page
  };

  // Play audio on component mount
  React.useEffect(() => {
    mixpanel.track('Part 3 Description Page Loaded');
    if (audioRef.current) {
      audioRef.current.play().catch(err => console.error('Audio failed to play:', err));
    }
    
    return () => {
      // Stop the audio when the component unmounts
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-neutral-50 flex flex-col items-center justify-center p-4">
      <button
        className="absolute top-4 left-4 text-xl"
        onClick={() => navigate('/')}  // Navigate to the home page
      >
        <ArrowLeftIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </button>
      
      <h1 className="text-2xl font-bold text-center mb-8">
        Part 3: Respond to questions
      </h1>
  
      <h2 className="text-base lg:text-lg mb-8 w-full max-w-4xl mx-auto">
        In Part 3, you will answer three questions. You will have 3 seconds to prepare after each question, and you will have 15 seconds to respond to the first two questions and 30 seconds for the third question.
      </h2>
  
      {/* Button to show/hide tips */}
      <button
        className="px-6 py-3 rounded-lg bg-blue-50 text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        Tips & Part 3 템플릿 확인하기
      </button>
  
      {/* Tips and Template Section */}
      {showTips && (
        <div className="w-full max-w-4xl mx-auto space-y-6">
  
          {/* Tip Box A */}
          <div className="bg-white p-6 rounded-lg border border-[#3488fe]">
            <p className="text-base lg:text-lg mb-4">A. Tips:</p>
            <p className="text-base lg:text-lg mb-4">
              파트3는 <span className="bg-blue-200">상황 설명</span> 문구가 제시된 후, 관련된 질문 3가지에 대답을 하는 문제입니다. <span className="bg-blue-200">정확한 문장 구성 (질문의 의문사/시제 활용)</span> 과 <span className="bg-blue-200">질문에서 묻는 것에 대해 명확히 답</span>을 하는 것이 중요합니다!
            </p>
            <p className="text-base lg:text-lg mb-4">
              준비 시간 동안 질문의 핵심 문장 구조를 파악하고 이를 바탕으로 답변을 준비해보세요.
            </p>
          </div>
  
          {/* Template Box */}
          <div className="bg-white p-6 rounded-lg border border-[#3488fe] space-y-4">
            <p className="text-base lg:text-lg mb-4">B. 질문의 문장 구조를 활용하여 명확히 답을 하는 예시:</p>
  
            <div className="space-y-4">
              {/* Example A */}
              <p className="text-base lg:text-lg">
                Q1: <span className="underline">How many grocery stores are in your neighborhood</span>, and <span className="underline">can you walk to them</span>?
              </p>
            
              <p className="text-base lg:text-lg text-gray-700">
                A1: <span className="bg-blue-200">There are two grocery stores in my neighborhood</span>, and  <span className="bg-blue-200">I can walk to both of them.</span> They are both about a 10-minute walk from my house.
              </p>
  
              {/* Example B */}
              <p className="text-base lg:text-lg">
                Q2:  <span className="underline">What is the best time of day to go to the grocery store</span>, and <span className="underline">why</span>?
              </p>
              
              <p className="text-base lg:text-lg text-gray-700">
                A2:  <span className="bg-blue-200">The best time of day to go to the grocery store</span> is [early morning on weekdays], <span className="bg-blue-200">because</span> [it's less crowded and the shelves are freshly stocked].
              </p>
            </div>
  
            {/* Background Description */}
            <p className="text-base lg:text-lg">
              Q3: <span className="underline">Do you usually buy all your groceries from the same store</span>? <span className="underline">Why</span> or why not?
            </p>
            
            <p className="text-base lg:text-lg text-gray-700">
              A3: <span className="bg-blue-200">I don't usually buy all my groceries from the same store because</span> different stores sell different things. Also, some stores have better prices. So, I go where I can save money. 
            </p>
          </div>
        </div>
      )}
  
      {/* Button to start practice */}
      <button
        className="px-6 py-3 rounded-lg bg-[#3488fe] text-lg text-white mt-8"
        onClick={startPractice}
      >
        파트 3 연습 시작하기!
      </button>
    </div>
  );
};

export default Part3Description;