import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';  // Use the AuthProvider
import mixpanel from 'mixpanel-browser';

const LoginPage = () => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { loginAction } = useAuth();  // Use loginAction from context
  const navigate = useNavigate();

  const handleLogin = async () => {
    // Clear previous error state
    setError('');

    if (userId && password) {
      // Attempt to login
      const isSuccess = await loginAction({ user_id: userId, password });
      if (isSuccess) {
        mixpanel.track('Sign In', { user_id: userId });
        navigate('/');  // Navigate to home if login is successful
      } else {
        // If loginAction returned false (e.g., non-200 status), show error
        setError('일치하는 계정이 없습니다.');
      }
    } else {
      setError('Please fill in all fields.');
    }
  };

  // Handle forgot password click
  const handleForgotPassword = () => {
    alert('비밀번호 변경 및 회원정보 수정이 필요하신 경우, 고객지원팀(baetos_kr@gmail.com)으로 문의 주시기 바랍니다.');
  };

  // Handle navigating to the SignUp page
  const handleSignUp = () => {
    navigate('/signup');  // Navigate to the SignUp page
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      {/* Back Button */}
      <button className="absolute top-4 left-4 text-xl" onClick={() => navigate(-1)}>
        ←
      </button>

      <h1 className="text-3xl font-bold mb-4">Log in</h1>

      {/* Username Input */}
      <input
        className="mb-2 p-4 w-80 bg-[#f9fafc] border border-gray-200 rounded-lg text-gray-700 placeholder-gray-500"
        type="text"
        placeholder="이메일"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
      />

      {/* Password Input */}
      <input
        className="mb-2 p-4 w-80 bg-[#f9fafc] border border-gray-200 rounded-lg text-gray-700 placeholder-gray-500"
        type="password"
        placeholder="비밀번호"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      {/* Error message (shown under password field) */}
      {error && <p className="text-red-500 w-80 text-left mb-2">{error}</p>}

      {/* Forgot Password Link */}
      <div className="w-80 text-right mb-4">
        <a href="#" className="text-[#3488fe]" onClick={handleForgotPassword}>
          비밀번호 찾기
        </a>
      </div>

      {/* Login Button */}
      <button
        className="w-80 px-4 py-4 bg-[#3488fe] text-white rounded-lg font-bold hover:bg-[#2670e8]"
        onClick={handleLogin}
      >
        로그인
      </button>

      {/* Sign-up Link */}
      <div className="mt-6">
        <p className="text-sm">
          아직 계정이 없으신가요?{' '}
          <a
            href="#"
            className="font-bold text-[#3488fe] underline"
            onClick={handleSignUp}
          >
            회원가입
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;