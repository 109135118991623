import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import RecordButton from '../RecordButton';  // A component for starting/stopping recording
import { useAuth } from '../../context/AuthProvider';  // Importing useAuth
import LoadingAlert from '../LoadingAlert';

const MockTestPart1Problems = ({ mocktestId }) => {
  const { user } = useAuth();  // Get the authenticated user ID
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timer, setTimer] = useState(45); // Timer for both preparation and answer time
  const [recording, setRecording] = useState(false);
  const navigate = useNavigate();
  const mediaRecorderRef = useRef(null); // Ref for the media recorder
  const timerRef = useRef(null); // Ref for the timer
  const audioChunks = useRef([]); // To store the recorded audio chunks
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch mocktest questions for Part 1
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mocktest${mocktestId}`)
      .then((response) => setQuestions(response.data.part1.questions))
      .catch((error) => console.error("Error fetching questions:", error));
  }, [mocktestId]);

  useEffect(() => {
    // Start the preparation countdown when a new question is displayed
    startPreparationCountdown();
  }, [currentQuestionIndex]);

  const startPreparationCountdown = () => {
    clearInterval(timerRef.current); // Clear any existing intervals to avoid multiple intervals running
    setTimer(45);  // Set preparation time to 45 seconds

    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          startRecording();  // Start recording once the preparation timer ends
        }
        return prev - 1;
      });
    }, 1000);
  };

  const startRecording = async () => {
    clearInterval(timerRef.current); // Clear the preparation timer
    setRecording(true);
    audioChunks.current = [];  // Clear any previous recordings

    // Start recording via microphone
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);  // Collect the audio chunks
        }
      };

      mediaRecorderRef.current.start();
      console.log("Microphone recording started");
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }

    // Start the answer countdown
    setTimer(45); // Answer timer is also 45 seconds
    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          stopRecording();  // Stop recording when the answer time ends
        }
        return prev - 1;
      });
    }, 1000);
  };

  const stopRecording = () => {
    setRecording(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop(); // Stop the media recorder
      console.log("Microphone recording stopped");
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
  
      // Ensure questions and currentQuestionIndex are valid before proceeding
      if (questions[currentQuestionIndex]) {
        mediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
          const formData = new FormData();
          formData.append('mocktest_id', mocktestId);
          formData.append('user_id', user.user_id);
          formData.append('question_id', questions[currentQuestionIndex].question_id);
          formData.append('audio_file', audioBlob, `${questions[currentQuestionIndex].question_id}.wav`);
          formData.append('current_part', 1);  // For Part 2
  
          try {
            setTimer(0);
            clearInterval(timerRef.current);
            setIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/submit-answer`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log('Answer submitted successfully:', response.data);
            if (response) {
              setIsLoading(false);
                // Move to the next question or part
                if (currentQuestionIndex < questions.length - 1) {
                  setCurrentQuestionIndex(currentQuestionIndex + 1);  // Move to the next question
                } else {
                  navigate(`/mocktest${mocktestId}/part2-instruction`);  // Navigate to the next part instruction when all questions are done
                }
              }
          } catch (error) {
            console.error('Error submitting answer:', error);
          }
        };
      } else {
        console.error("Question not found for the current index.");
      }
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-start">
      {/* Display question index at the top with gray background */}
      {isLoading && (
        <LoadingAlert 
          message="딥변을 분석 중입니다. 최대 15초 소요될 수 있습니다."
        />
      )}
      <h1 className="text-xl lg:text-2xl font-bold text-center mb-6 bg-gray-300 w-full py-4">
        Questions {currentQuestionIndex + 1} of 11
      </h1>

      {questions.length > 0 && (
        <div className="bg-white rounded-lg p-6 lg:p-8 w-full max-w-3xl mb-6">
          <p className="text-base md:text-lg lg:text-xl text-gray-700 mb-6 ">
            {questions[currentQuestionIndex].text}
          </p>
        </div>
      )}
     
      {questions.length > 0 && (
        <>
          <p className="text-lg lg:text-xl text-gray-800 mb-6 text-center">
            {recording ? "Recording Time" : "Preparation Time"}: {timer} seconds
          </p>
          <div className="flex justify-center">
            <RecordButton recording={recording} onStart={startRecording} onStop={stopRecording} />
          </div>
        </>
      )}
    </div>
  );
};

export default MockTestPart1Problems;