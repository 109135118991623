import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import useMixpanel from '../hooks/useMixpanel';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const Part5Description = () => {
  useMixpanel();
  const navigate = useNavigate();
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility

  const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/part5-question.mp3`));

  // Start the practice and stop the audio
  const startPractice = () => {
    if (audioRef.current) {
      audioRef.current.pause();  // Stop the audio
    }
    navigate('/practice-part5');  // Navigate to the practice page
  };

  // Play audio on component mount
  React.useEffect(() => {
    mixpanel.track('Part 5 Description Page Loaded');
    if (audioRef.current) {
      audioRef.current.play().catch(err => console.error('Audio failed to play:', err));
    }
    
    return () => {
      // Stop the audio when the component unmounts
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <button
        className="absolute top-4 left-4 text-xl"
        onClick={() => navigate('/')}  // Navigate to the home page
      >
        <ArrowLeftIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </button>

      <h1 className="text-2xl font-bold text-center mb-8">
        Part 5: Express an opinion
      </h1>

      {/* h2 with same width as the tips box */}
      <h2 className="text-base lg:text-lg mb-8 w-full max-w-4xl mx-auto">
        In this part of the practice, you will give your opinion about a specific topic. Be sure to say as much as you can in the time allowed. You will have 45 seconds to prepare. Then you will have 60 seconds to speak.
      </h2>

      {/* Button to show/hide tips */}
      <button
        className="px-6 py-3 rounded-lg bg-[#F5F0E5] text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        Tips & Part5 템플릿 공부하기
      </button>

      {/* Box for tips and template with stroke color */}
      {showTips && (
        <div className="border-2 border-[#A1824A] p-6 rounded-lg w-full max-w-4xl mx-auto">
          <p className="text-base lg:text-lg mb-4">
          <strong>팁:</strong> 파트 5는 동의/비동의 문제에요. 준비시간 45초 동안 주어진 문장에 대해 빠르게 왜 동의/비동의 하는지 이유 2가지 정도의 키워드 (명사, 동사들) 노트에 간단히 적어보고, 다음의 템플릿을 이용해 보세요.
          </p>

          {/* Template Section */}
          <div className="mb-6">
           
            <p className="text-base lg:text-lg mb-4">
              <strong>1. </strong> 첫 문장은, I agree/disagree with the statement that (제시된 논의) 
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>2.</strong> I have 2/3 reasons to support my opinion. 
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>3.</strong> First, (첫 번째 이유). Second, (두 번째 이유). 자신의 예시를 제시할 수 있다면, For example, when I was ... 
            </p>
            <p className="text-base lg:text-lg mb-4">
              <strong>4. </strong>마지막으로 한 번 더 질문에 제시된 문장을 활용해서, In conclusion, these are why I think ...
            </p>
         
       
          </div>
        </div>
      )}

      {/* Button with updated color */}
      <button
        className="px-6 py-3 rounded-lg bg-[#009963] text-xl text-white mt-8"
        onClick={startPractice}
      >
        파트 5 연습 시작하기!
      </button>
    </div>
  );
};

export default Part5Description;