import React, { useState } from 'react';
import { ColorStatics } from '../statics';

const LoadingAlert = ({ message }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 text-center">
        <p className="mb-4">{message}</p>
        <div className="flex items-center justify-center">
          {/* Using the SVG from the public folder */}
          <img
            src="/3-dots-bounce.svg"
            alt="Loading"
            className="h-8 w-8"
          />
        </div>
      </div>
    </div>
  );
};

export default LoadingAlert;