import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../context/AuthProvider';
import RecordButton from '../components/RecordButton';
import axios from 'axios';
import { SparklesIcon, HomeIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const PracticePart2 = () => {
  const { user } = useAuth();
  const [image, setImage] = useState('');
  const [annotation, setAnnotation] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [progress, setProgress] = useState({ completed: 0, total: 50 });
  const [completedQuestions, setCompletedQuestions] = useState([]);
  const [timer, setTimer] = useState(45);  // Start with preparation timer set to 45 seconds
  const [recording, setRecording] = useState(false);
  const [recordingCompleted, setRecordingCompleted] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [aiFeedback, setAiFeedback] = useState('');
  const [activeTab, setActiveTab] = useState('transcription');
  const [hasSubscription, setHasSubscription] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const timerRef = useRef(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  // Fetch the user's progress and the first question
  useEffect(() => {
    if (user) {
      mixpanel.track('Part 2 Practice Started', { user_id: user.user_id });
      
      Promise.all([
        axios.get(`${API_BASE_URL}/api/get-part2-progress/${user.user_id}`),
        axios.get(`${API_BASE_URL}/api/landing-data/${user.user_id}`)
      ])
        .then(([progressResponse, landingResponse]) => {
          // Handle progress data
          const { completed, total, completedQuestions } = progressResponse.data;
          setProgress({ completed, total });
          setCompletedQuestions(completedQuestions);

          // Handle subscription data
          const { subscriptionEndDate } = landingResponse.data;
          
          let isActive = false;
          if (subscriptionEndDate) {
            const currentDate = new Date().toISOString();
            const endDate = new Date(subscriptionEndDate).toISOString();
            isActive = endDate > currentDate;
          }

          // Set subscription status first
          setHasSubscription(isActive);
          
          // Calculate next question
          const nextQuestionId = completed === 0 ? 'p2_i_001' : `p2_i_${String(completed + 1).padStart(3, '0')}`;
          
          // Only proceed if user has subscription or it's the first question
          if (isActive || nextQuestionId === 'p2_i_001') {
            fetchNextQuestion(nextQuestionId);
          } else {
            setIsModalOpen(true);
          }
        })
        .catch(error => {
          console.error("Error fetching initial data:", error);
        });
    }
  }, [user, API_BASE_URL]);

  useEffect(() => {
    // Automatically start recording when preparation timer hits 0 and it's not recording yet
    if (timer === 0 && !recording && !recordingCompleted) {
      startRecordingTimer();
    }
  
    // Automatically stop recording when the recording timer hits 0
    if (timer === 0 && recording) {
      stopRecording();
    }
  }, [timer, recording, recordingCompleted]);

  const fetchNextQuestion = (nextQuestionId) => {
    if (!user) return;

    console.log('Fetching next question:', {
      nextQuestionId,
      hasSubscription
    });

    // Check subscription status
    if (!hasSubscription && nextQuestionId !== 'p2_i_001') {
      console.log('No subscription, blocking access to question:', nextQuestionId);
      setIsModalOpen(true);
      return;
    }
    axios.get(`${API_BASE_URL}/api/get-part2-question-by-id/${nextQuestionId}`)
      .then((response) => {
        setImage(response.data.image);
        setAnnotation(response.data.annotation);
        setQuestionId(nextQuestionId);
        resetStateForNextQuestion();
        startCountdown(45);
      })
      .catch(error => {
        console.error("Error fetching the next question:", error);
      });
  };
 

  const fetchPreviousQuestion = () => {
    if (questionId === 'p2_i_001') return;
    const prevIdNumber = parseInt(questionId.split('_').pop()) - 1;
    const prevQuestionId = `p2_i_${String(prevIdNumber).padStart(3, '0')}`;
    fetchNextQuestion(prevQuestionId);
  };

  const resetStateForNextQuestion = () => {
    clearInterval(timerRef.current);
    setTimer(45);
    setRecording(false);
    setRecordingCompleted(false);
    setTranscription('');
    setAiFeedback('');
    setActiveTab('transcription');
  };

  const startCountdown = (duration) => {
    setTimer(duration);
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setTimer(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          if (recording) {
            stopRecording();  // Automatically stop recording when time runs out
          }
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const startRecordingTimer = () => {
    clearInterval(timerRef.current);
    setTimer(30);  // Set recording timer to 30 seconds
    setRecording(true);
    setRecordingCompleted(false);
    startCountdown(30);  // Start the 30-second recording timer
    handleStartRecording();

    mixpanel.track('Part 2 Recording Started', { user_id: user.user_id, question_id: questionId });
  };

  const handleStartRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    recordedChunksRef.current = [];

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunksRef.current.push(event.data);
      }
    };

    mediaRecorderRef.current.start();
  };

  const stopRecording = () => {
    clearInterval(timerRef.current);
    setRecording(false);  // Mark recording as stopped
    setTimer(0);  // Reset timer to 0

    handleStopRecording();
    setRecordingCompleted(true);  // Set recording as completed

    mixpanel.track('Part 2 Recording Stopped', { user_id: user.user_id, question_id: questionId });
  };

  const handleStopRecording = () => {
    if (!mediaRecorderRef.current || mediaRecorderRef.current.state === 'inactive') return;

    mediaRecorderRef.current.stop();

    const stream = mediaRecorderRef.current.stream;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());  // Stop all media tracks to remove recording icon
    }

    mediaRecorderRef.current.onstop = async () => {
      const blob = new Blob(recordedChunksRef.current, { type: 'audio/webm' });
      const file = new File([blob], 'recording.webm', { type: 'audio/webm' });

      const formData = new FormData();
      formData.append('file', file);
      formData.append('model_answer', annotation);

      try {
        const response = await axios.post(`${API_BASE_URL}/api/speech-to-text`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setTranscription(response.data.transcription);
        setAiFeedback(response.data.ai_feedback);
      } catch (error) {
        console.error("Error transcribing audio:", error);
      }
    };

    mediaRecorderRef.current = null;  // Clear the media recorder reference
  };

  const handleNextQuestion = () => {
    if (!user || !questionId) return;

    if (hasSubscription) {
      axios.post(`${API_BASE_URL}/api/complete-part2-question/${user.user_id}/${questionId}`)
        .then(() => {
          setProgress(prev => ({
            ...prev,
            completed: prev.completed + 1,
          }));
          const nextIdNumber = parseInt(questionId.split('_').pop()) + 1;
          const nextQuestionId = `p2_i_${String(nextIdNumber).padStart(3, '0')}`;
          fetchNextQuestion(nextQuestionId);

          mixpanel.track('Part 2 Question Completed', { user_id: user.user_id, question_id: questionId });
        })
        .catch(error => {
          console.error("Error marking the question as completed:", error);
        });
    } else {
      setIsModalOpen(true);
    }
  };

  // Split AI feedback based on numbers (like 1., 2.) or dashes (-) and handle **bold** formatting
  const formatAiFeedback = (feedback) => {
    // Split by numbered points, bullet point dashes (followed by space), and bold text
    const parts = feedback.split(/(\d+\.\s|-\s|\*\*.*?\*\*)/);
  
    return parts.map((part, index, array) => {
      // Bold if part is wrapped in ** **
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      
      // Add a new line before numbers with periods or dashes with spaces ONLY if it's not at the start
      if (part.match(/\d+\.\s|-\s/)) {  // Note: -\s means dash followed by whitespace
        // Check if this is effectively the start of the content
        const previousContent = array.slice(0, index).join('').trim();
        if (previousContent) {
          return <><br key={index} />{part}</>;
        }
        return part;
      }
      return part;
    });
  };
  const fetchQuestionById = (id) => {
    const questionId = `p2_i_${String(id).padStart(3, '0')}`;
    fetchNextQuestion(questionId);
  };
  // console.log("completedQuestions.length", completedQuestions.length);
  const progressPercentage = (completedQuestions.length / progress.total) * 100;

  const handleDropdownChange = (event) => {
    const selectedQuestionId = parseInt(event.target.value);
    if (selectedQuestionId <= progress.completed) {
      fetchQuestionById(selectedQuestionId);
    }
  };
  const handleHomeClick = () => {
    navigate('/'); // Redirect to the landing page
  };

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);

    // Track tab click event
    mixpanel.track('Part 2 Feedback Tab Clicked', { user_id: user.user_id, tab });
  };

  // console.log("questionId", questionId);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-start p-4">
      <div className="w-full max-w-4xl">
        {/* Top Row: Previous Button and Title */}
        <div className="flex justify-between items-center mb-4">
          {/* {questionId !== 'p2_i_001' && ( */}
            <button className="text-base sm:text-lg" onClick={handleHomeClick}>
              <HomeIcon className="w-6 h-6" /> {/* Replace text with HomeIcon */}
            </button>
          {/* )} */}
          <h1 className="text-base sm:text-lg font-bold">Part 2 연습</h1>
          <div className="w-24"></div> {/* Placeholder for alignment */}
        </div>

        {/* Dropdown for Questions */}
        <div className="mb-4">
          <select
            className="border rounded p-2 w-full"
            value={parseInt(questionId.split('_')[2])}  // Correctly extract the current question number
            onChange={handleDropdownChange}
          >
            {Array.from({ length: progress.completed + 1 }, (_, i) => i + 1).map(number => (
              <option key={number} value={number}>
                Question {number}
              </option>
            ))}
          </select>
        </div>

        {/* Progress Bar */}
        <div className="mb-4">
          <p className="text-base sm:text-lg">진행상황</p>
          <div className="relative w-full h-4 bg-gray-300 rounded">
            <div
              className="absolute h-full bg-[#3488fe] rounded"
              style={{ width: `${progressPercentage}%` }}
            ></div>

            {/* Display the completed number under the progress point */}
            <div
              className="absolute top-6 text-sm sm:text-base"
              style={{ left: `calc(${progressPercentage}% - 10px)` }} // Adjust the position
            >
              {completedQuestions.length}
            </div>
          </div>

          {/* Total number on the right */}
          <div className="flex justify-between mt-2 text-sm sm:text-base">
            <span></span> {/* Empty span for alignment */}
            <span>{progress.total}</span>
          </div>
        </div>

        <div className="w-full flex justify-center mb-4">
          <div className="w-96 h-96 flex items-center justify-center">
            {image ? (
              <>
                
                <img 
                  src={`${process.env.REACT_APP_S3_BASE_URL}/images/${image}`} 
                  alt="Baetos Part2 Question Assets"
                  className="max-w-full max-h-full object-contain rounded-lg"
                  onError={(e) => {
                    console.error('Image failed to load:', {
                      src: e.target.src,
                      error: e
                    });
                  }}
                />
              </>
            ) : (
              <p>Loading image...</p>
            )}
          </div>
        </div>

        <div className="mt-4 flex flex-col items-center">
          <p className="text-lg mb-8">Time left: {timer}초</p>
          <RecordButton
            recording={recording}
            onStart={startRecordingTimer}
            onStop={stopRecording}
            buttonText={
              recording ? "Stop Recording" : (recordingCompleted ? "Re-try" : "Start Recording")
            }
          />
        </div>

        {transcription && (
          <div className="mt-6">
            {/* Tabs */}
            <div className="flex justify-between border-b-2 border-[#E5E8EB]">
              <div
                className={`cursor-pointer text-lg ${
                  activeTab === 'transcription' 
                    ? 'border-b-4 border-[#3488fe] text-black' 
                    : 'border-none'
                }`}
                onClick={() => handleTabSwitch('transcription')}
              >
                나의 답변
              </div>
              <div
                className={`cursor-pointer text-lg flex items-center ${
                  activeTab === 'aiFeedback' 
                    ? 'border-b-4 border-[#3488fe] text-black' 
                    : 'border-none'
                }`}
                onClick={() => handleTabSwitch('aiFeedback')}
              >
                AI 피드백
                <SparklesIcon className="w-5 h-5 ml-2 text-yellow-500" />
              </div>
              <div
                className={`cursor-pointer text-lg ${
                  activeTab === 'modelAnswer' 
                    ? 'border-b-4 border-[#3488fe] text-black' 
                    : 'border-none'
                }`}
                onClick={() => handleTabSwitch('modelAnswer')}
              >
                모범답안
              </div>
            </div>

            {/* Tab Contents */}
            {activeTab === 'transcription' && (
              <div className="mt-4 p-4 bg-white shadow-lg rounded-lg">
                {/* <h2 className="text-xl font-bold">Your Answer</h2> */}
                <p>{transcription}</p>
              </div>
            )}

            {activeTab === 'aiFeedback' && (
              <div className="mt-4 p-4 bg-white shadow-lg rounded-lg">
                {/* <h2 className="text-xl font-bold">AI Feedback</h2> */}
                <p>{formatAiFeedback(aiFeedback)}</p>
              </div>
            )}

            {activeTab === 'modelAnswer' && (
              <div className="mt-4 p-4 bg-white shadow-lg rounded-lg">
                {/* <h2 className="text-xl font-bold">Model Answer</h2> */}
                <p>{annotation}</p>
              </div>
            )}

            {/* Next Question Button */}
            <button
              onClick={handleNextQuestion}
              className="px-6 py-3 mt-6 w-full rounded-lg bg-[#3488fe] text-white text-base sm:text-lg"
            >
              Next Question
            </button>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-md">
            <h2 className="text-xl font-bold mb-4">구독이 필요합니다</h2>
            <p className="mb-4">더 많은 문제를 풀어보시려면 구독해주세요.</p>
            <button
              onClick={() => navigate('/pricing')}
              className="w-full bg-[#3488fe] text-white py-2 rounded-lg"
            >
              구독하기
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PracticePart2;