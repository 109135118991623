// src/pages/PricingPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';

const PricingPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handlePurchase = (plan) => {
    if (!user) {
      navigate('/login');
      return;
    }
    // TODO: Implement payment logic
    alert('Payment functionality coming soon!');
  };

  return (
    <div className="min-h-screen bg-[#f9fafc] py-12 px-4">
      {/* Back Button */}
      <button 
        className="absolute top-4 left-4 text-xl" 
        onClick={() => navigate(-1)}
      >
        ←
      </button>

      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold mb-4">이용권 구매</h1>
          <p className="text-lg text-[#3488fe] font-semibold">🎉 2025년 설 연휴 40% 할인 프로모션 진행중!</p>
        </div>

        {/* Mock Tests Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-2">토익스피킹 실전모의고사</h2>
          <p className="text-gray-600 mb-6">
            실전과 동일한 환경에서 모의고사를 진행하고, 배토스 AI 채점 시스템으로 예상 점수와 피드백 리포트를 받아보세요.<br/>
            
          </p>
          <div className="grid md:grid-cols-2 gap-6">
            {/* Single Mock Test */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">1회 모의고사</h3>
              <div className="mb-4">
                <span className="text-gray-500 line-through text-lg">$12</span>
                <p className="text-3xl font-bold text-[#3488fe]">$7</p>
                <span className="inline-block bg-red-100 text-red-600 px-2 py-1 rounded-full text-sm">40% OFF</span>
              </div>
              <button
                onClick={() => handlePurchase('single-mock')}
                className="w-full bg-[#3488fe] text-white py-2 rounded-lg hover:bg-[#2670e8]"
              >
                구매하기
              </button>
            </div>

            {/* 3 Mock Tests Bundle */}
            <div className="bg-white p-6 rounded-lg shadow-md border-2 border-[#3488fe]">
              <div className="flex justify-between items-start mb-4">
                <h3 className="text-xl font-semibold">3회 모의고사 패키지</h3>
                <span className="bg-[#3488fe] text-white px-2 py-1 rounded-lg text-sm">
                  Best Value
                </span>
              </div>
              <div className="mb-4">
                <span className="text-gray-500 line-through text-lg">$36</span>
                <p className="text-3xl font-bold text-[#3488fe]">$15</p>
                <span className="inline-block bg-red-100 text-red-600 px-2 py-1 rounded-full text-sm">58% OFF</span>
              </div>
              <p className="text-sm text-gray-600 mb-4">40% 할인 + 패키지 추가 할인!</p>
              <button
                onClick={() => handlePurchase('three-mock')}
                className="w-full bg-[#3488fe] text-white py-2 rounded-lg hover:bg-[#2670e8]"
              >
                구매하기
              </button>
            </div>
          </div>
        </div>

        {/* Subscription Section */}
        <div>
          <h2 className="text-2xl font-semibold mb-2">무제한 연습/피드백 이용권</h2>
          <p className="text-gray-600 mb-6">
            파트별 문제은행과 템플릿 문장 영작 연습을 무제한으로 이용하실 수 있습니다. 각 문제에 따른 실시간 피드백도 함께 무제한으로 제공됩니다.<br/>
            
          </p>
          <div className="grid md:grid-cols-3 gap-6">
            {/* 7 Days */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">7일 이용권</h3>
              <div className="mb-4">
                <span className="text-gray-500 line-through text-lg">$10</span>
                <p className="text-3xl font-bold text-[#3488fe]">$6</p>
                <span className="inline-block bg-red-100 text-red-600 px-2 py-1 rounded-full text-sm">40% OFF</span>
              </div>
              <button
                onClick={() => handlePurchase('7-days')}
                className="w-full bg-[#3488fe] text-white py-2 rounded-lg hover:bg-[#2670e8]"
              >
                구매하기
              </button>
            </div>

            {/* 14 Days */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-4">14일 이용권</h3>
              <div className="mb-4">
                <span className="text-gray-500 line-through text-lg">$15</span>
                <p className="text-3xl font-bold text-[#3488fe]">$9</p>
                <span className="inline-block bg-red-100 text-red-600 px-2 py-1 rounded-full text-sm">40% OFF</span>
              </div>
              <button
                onClick={() => handlePurchase('14-days')}
                className="w-full bg-[#3488fe] text-white py-2 rounded-lg hover:bg-[#2670e8]"
              >
                구매하기
              </button>
            </div>

            {/* 1 Month */}
            <div className="bg-white p-6 rounded-lg shadow-md border-2 border-[#3488fe]">
              <div className="flex justify-between items-start mb-4">
                <h3 className="text-xl font-semibold">1개월 이용권</h3>
                <span className="bg-[#3488fe] text-white px-2 py-1 rounded-lg text-sm">
                  Most Popular
                </span>
              </div>
              <div className="mb-4">
                <span className="text-gray-500 line-through text-lg">$20</span>
                <p className="text-3xl font-bold text-[#3488fe]">$12</p>
                <span className="inline-block bg-red-100 text-red-600 px-2 py-1 rounded-full text-sm">40% OFF</span>
              </div>
              <p className="text-sm text-gray-600 mb-4">가장 인기있는 요금제!</p>
              <button
                onClick={() => handlePurchase('1-month')}
                className="w-full bg-[#3488fe] text-white py-2 rounded-lg hover:bg-[#2670e8]"
              >
                구매하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;