import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import useMixpanel from '../hooks/useMixpanel';

const MockTestDescription = () => {
  useMixpanel();
  const navigate = useNavigate();
  const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/mocktest-description.mp3`));  // Placeholder for audio file
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility

  const handleStartTest = () => {
    if (audioRef.current) {
      audioRef.current.pause();  // Stop the audio when starting the test
    }
    navigate('/mocktest1');  // Navigate to the mock test page
  };

  // Play the audio on component mount
  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play().catch((err) => console.error('Audio failed to play:', err));
    }

    return () => {
      // Stop the audio when the component unmounts
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <button
        className="absolute top-4 left-4 text-xl"
        onClick={() => navigate('/')}  // Navigate to the home page
      >
        <ArrowLeftIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </button>
      <h1 className="text-2xl font-bold text-center mb-8">
        배토스 토익 스피킹 1회 모의고사 안내
      </h1>
      <h2 className="text-lg lg:text-xl mb-8 w-full max-w-4xl mx-auto">
       현재 모바일에서는 파트1까지만 지원합니다. 데스크탑 (크롬 브라우저 권장) 에서 전체 모의고사를 진행해주세요.
      </h2>

      {/* Main Description */}
      <div className="bg-white p-6 rounded-lg  w-full max-w-4xl mx-auto mb-8">
        <p className="mb-6 ">
          각 파트별 설명과 질문을 듣기 위해 볼륨을 켜주세요 📢
        </p>
        <p className="mb-4">
          모의고사는 약 15분 정도 소요되며, 크롬 브라우저에서 데스크탑을 사용하여 응시하는 것을 권장합니다.
        </p>
        <p className="mb-4">
          인터넷이 잘 되는 조용한 환경에서 음성을 인식할 수 있도록 준비해주세요. <strong>시험 도중에는 멈출 수 없습니다.</strong>
        </p>
      </div>

      {/* Button to show/hide tips */}
      <button
        className="px-6 py-3 rounded-lg bg-[#F5F0E5] text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        Tips & Part별 설명
      </button>

      {/* Tips Section */}
      {showTips && (
        <div className="border-2 border-[#A1824A] p-6 rounded-lg w-full max-w-4xl mx-auto mb-6">
          <ul className="list-disc list-inside mb-4 text-base lg:text-lg">
            <li className="mb-4">
              <strong>Part 1:</strong> 주어진 문단을 읽는 문제로, 준비시간 45초와 답변시간 45초가 주어집니다. 발음, 억양, 강세를 연습하세요.
            </li>
            <li className="mb-4">
              <strong>Part 2:</strong> 제시된 사진을 묘사하는 문제로, 준비시간 45초와 답변시간 30초가 주어집니다. 사진 속 대상과 동작을 정리하세요.
            </li>
            <li className="mb-4">
              <strong>Part 3:</strong> 안내문에 관한 질문에 답하는 문제로, 각 질문에 대해 준비시간 3초와 답변시간 15초, 15초, 30초가 주어집니다.
            </li>
            <li className="mb-4">
              <strong>Part 4:</strong> 제시된 정보를 참고하여 문제에 답하는 문제로, 준비시간 45초와 답변시간 15초, 15초, 30초가 주어집니다.
            </li>
            <li>
              <strong>Part 5:</strong> 주어진 주제에 대해 자신의 의견을 말하는 문제로, 준비시간 45초와 답변시간 60초가 주어집니다.
            </li>
          </ul>
        </div>
      )}

      {/* Start Test Button */}
      <button
        className="px-6 py-3 rounded-lg bg-[#009963] text-lg text-white mt-8 w-full max-w-4xl mx-auto"
        onClick={handleStartTest}
      >
        모의고사 1회 시작하기
      </button>
    </div>
  );
};

export default MockTestDescription;