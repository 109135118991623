import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import useMixpanel from '../hooks/useMixpanel';

const Part2Description = () => {
  useMixpanel();
  const navigate = useNavigate();
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility
  const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/part2-practice-prompt.mp3`));  // Part 2 audio

  // Start the practice and stop the audio
  const startPractice = () => {
    if (audioRef.current) {
      audioRef.current.pause();  // Stop the audio
    }
    navigate('/practice-part2');  // Navigate to Part 2 practice page
  };

  // Play audio on component mount
  React.useEffect(() => {
    mixpanel.track('Part 2 Description Page Loaded');

    if (audioRef.current) {
      audioRef.current.play().catch(err => console.error('Audio failed to play:', err));
    }
    
    return () => {
      // Stop the audio when the component unmounts
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-neutral-50 flex flex-col items-center justify-center p-4">
      {/* Back Icon at the top-left corner */}
      <button
        className="absolute top-4 left-4 text-xl"
        onClick={() => navigate('/')}  // Navigate to the home page
      >
        <ArrowLeftIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </button>
      
      <h1 className="text-2xl font-bold text-center mb-8">
        Part 2: Describe a picture
      </h1>

      {/* Description */}
      <h2 className="text-base lg:text-lg mb-8 w-full max-w-4xl mx-auto">
        In this part of the practice, you will describe the picture on your screen in as much detail as you can. You will have 45 seconds to prepare your response. Then you will have 30 seconds to speak about the picture.
      </h2>

      {/* Button to show/hide tips */}
      <button
        className="px-6 py-3 rounded-lg bg-blue-50 text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        Tips & Part2 템플릿 확인하기
      </button>

      {/* Tips and Template Section */}
      {showTips && (
        <div className="w-full max-w-4xl mx-auto space-y-6">
          
          {/* Tip Box A */}
          <div className="bg-white p-6 rounded-lg border border-[#3488fe]">
            <p className="text-base lg:text-lg mb-4">A. Tips:</p>
            <p className="text-base lg:text-lg mb-4">
              파트2는 <span className="bg-blue-100">사진 묘사하기</span> 문제입니다. 정확한 어휘, 발음, 문법을 사용하면 만점을 받을 수 있어요.
            </p>
         
            {/* <p className="text-base lg:text-lg mb-4 underline">B. 준비 전략:</p> */}
            <p className="text-base lg:text-lg mb-4">
              준비시간 45초 동안, 강조된 부분에 대한 명사 (장소, 인물, 사물) 와 그에 대한 동사 (행동), 형용사 (수량, 색깔, 감정 등) 를 노트에 적어보세요, 실전처럼!
            </p>
          </div>

          {/* Template Box */}
          <div className="bg-white p-6 rounded-lg border border-[#3488fe] space-y-4">
            <p className="text-base lg:text-lg mb-4">B. 파트 2 만점 템플릿:</p>

            <div className="space-y-4">
              {/* Example A */}
              <p className="text-base lg:text-lg">
                1. 첫 문장은 <span className="bg-blue-100">장소 설명</span>으로 시작:  
                <span className="bg-blue-200">The picture was taken</span> in/at/by (장소) during (날씨/시간대).
              </p>
              <p className="text-base lg:text-lg text-gray-700">
                예: The picture was taken in a park during a rainy day(낮)/night(밤).
              </p>

              {/* Example B */}
              <p className="text-base lg:text-lg">
                2. <span className="bg-blue-100">인물의 수 및 위치/동작</span> 설명:
                There are two people + (동작 설명 혹은 사진 속에서의 위치 언급).
              </p>
              <p className="text-base lg:text-lg text-gray-700">
                예: <span className="bg-blue-200">There are two people </span>(sitting on a bench / on the left side of the picture).
              </p>
              <p className="text-base lg:text-lg">
                3. <span className="bg-blue-100">추가적인 사람의 동작/인상착의나 사물</span>을 위치와 함께 설명:
              </p>
              <p className="text-base lg:text-lg text-gray-700">
                예: <span className="bg-blue-200">The man in the middle of the picture is wearing</span> a blue shirt and glasses. <span className="bg-blue-200">I can also see</span> papers scattered on the table.
              </p>
              
            </div>

            {/* Background Description */}
            <p className="text-base lg:text-lg">
              4. <span className="bg-blue-100">배경</span>에서 눈에 띄는 것이 있다면 설명: <span className="bg-blue-200">In the background</span>, I can see (trees and buildings).
            </p>
          </div>
        </div>
      )}

      {/* Button to start practice */}
      <button
        className="px-6 py-3 rounded-lg bg-[#3488fe] text-lg text-white mt-8"
        onClick={startPractice}
      >
        파트 2 연습 시작하기!
      </button>
    </div>
  );
};

export default Part2Description;