import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { SparklesIcon, HomeIcon } from '@heroicons/react/24/outline';

const Part5TemplatePracticeLearning = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAuth(); // Get the authenticated user
  const navigate = useNavigate();

  const [question, setQuestion] = useState(null); // Current question data
  const [recording, setRecording] = useState(false); // Recording state
  const [userAnswer, setUserAnswer] = useState(''); // User's transcribed answer
  const [feedback, setFeedback] = useState(''); // Feedback from AI
  const [progress, setProgress] = useState({ completed: 0, total: 0 }); // User progress
  const [questionId, setQuestionId] = useState('q3_t_learning_01'); // Starting question ID (adjust as needed)
  const [activeTab, setActiveTab] = useState('aiFeedback'); // To switch between tabs
  const [hasSubscription, setHasSubscription] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const mediaRecorderRef = useRef(null); // Ref for media recorder
  const recordedChunksRef = useRef([]); // To store recorded chunks
  const [userRecordingAudioBlob, setUserRecordingAudioBlob] = useState(null); // Store the recorded audio
  
  const fileName = 'part5_template_learning.json'; // JSON file for this practice

  // Fetch initial progress and question when the component mounts
  useEffect(() => {
    if (user) {
      mixpanel.track('Part 5 Template Practice Learning Page Loaded', {user_id: user.user_id});
      
      Promise.all([
        axios.get(`${API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
          params: { file_name: fileName }
        }),
        axios.get(`${API_BASE_URL}/api/landing-data/${user.user_id}`)
      ])
        .then(([progressResponse, landingResponse]) => {
          setProgress({
            completed: progressResponse.data.completed,
            total: progressResponse.data.total,
          });

          // Handle subscription data
          const { subscriptionEndDate } = landingResponse.data;
          
          let isActive = false;
          if (subscriptionEndDate) {
            const currentDate = new Date().toISOString();
            const endDate = new Date(subscriptionEndDate).toISOString();
            isActive = endDate > currentDate;
          }

          setHasSubscription(isActive);
          
          // Only proceed if user has subscription or it's the first question
          if (isActive || questionId === 'q3_t_learning_01') {
            fetchQuestion(questionId);
          } else {
            setIsModalOpen(true);
          }
        })
        .catch(error => {
          console.error('Error fetching initial data:', error);
        });
    }
  }, [user]);

  // Fetch user progress from the backend
  const fetchProgress = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
        params: { file_name: fileName }  // Send file_name as query param
      });
      setProgress({
        completed: response.data.completed,
        total: response.data.total,
      });
    } catch (error) {
      console.error('Error fetching progress:', error);
    }
  };
  
  // Fetch the current question by its ID
  const fetchQuestion = async (qid) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/get-template-question-by-id/${user.user_id}/${qid}`, {
        params: { file_name: fileName }  // Send file_name as query param
      });
      setQuestion(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching question:', error);
    }
  };

  // Start recording user input
  const handleStartRecording = async () => {
    try {
      setRecording(true);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      recordedChunksRef.current = [];
  
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };
  
      mediaRecorderRef.current.start();
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  // Stop recording and transcribe the audio
  const handleStopRecording = async () => {
    setRecording(false);

    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop(); // Stop recording
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop()); // Stop the tracks
    }

    mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(recordedChunksRef.current, { type: 'audio/webm' });  // Make sure the blob is .webm
        setUserRecordingAudioBlob(blob);
      
        const formData = new FormData();
        formData.append('file', blob, 'recording.webm');  // Ensure the file is saved as .webm
        formData.append('model_answer', question.answer); 
        formData.append('question', question.question);
      
        try {
          const response = await axios.post(`${API_BASE_URL}/api/template-speech-to-text`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
      
          const transcribedText = response.data.transcription;
          setUserAnswer(transcribedText);
          setFeedback(response.data.ai_feedback);
      
        } catch (error) {
          console.error('Error uploading and processing the audio file:', error);
        }
      };
  };

  // Retry the current question
  const handleRetry = () => {
    setFeedback('');
    setUserAnswer('');
  };

  // Mark the current question as completed and fetch the next one
  const handleNextQuestion = async () => {
    if (!hasSubscription && questionId === 'q3_t_learning_01') {
      setIsModalOpen(true);
      return;
    }

    try {
      await axios.post(`${API_BASE_URL}/api/complete-template-question/${user.user_id}/${'learning'}/${questionId}`);
      const nextQuestionId = determineNextQuestionId();
      
      if (!hasSubscription) {
        setIsModalOpen(true);
        return;
      }
      
      setQuestionId(nextQuestionId);
      fetchQuestion(nextQuestionId);
      setFeedback('');
      setUserAnswer('');

      mixpanel.track('Part 5 Template Practice Learning Question Completed', {question_id: questionId, user_id: user.user_id});
    } catch (error) {
      console.error('Error completing question:', error);
    }
  };

  // Determine the next question ID
    const determineNextQuestionId = () => {
        const currentIdNum = parseInt(questionId.split('_').pop(), 10);
        const nextIdNum = currentIdNum + 1;
        if (nextIdNum > 20) {
        alert("You have reached the last question.");
        return questionId;  // Stay on the current question
        }
        return `q3_t_learning_${nextIdNum.toString().padStart(2, '0')}`;
    };

  // Determine the previous question ID
  const determinePreviousQuestionId = () => {
    const currentIdNum = parseInt(questionId.split('_').pop(), 10);
    const prevIdNum = Math.max(1, currentIdNum - 1);  // Ensure the question ID doesn't go below 1
    return `q3_t_learning_${prevIdNum.toString().padStart(2, '0')}`;
  };



  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    mixpanel.track('Part 5 Template Practice Learning Tab Switched', {tab: tab});
  };

  const handleHomeClick = () => {
    navigate('/'); // Redirect to the landing page
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 p-4">
      <div className="w-full max-w-md sm:max-w-lg lg:max-w-xl"> {/* Adjust width for larger screens */}
        {/* Title */}
        <div className="flex justify-between items-center mb-6"> {/* Increased bottom margin */}
          <button className="text-base sm:text-lg" onClick={handleHomeClick}>
            <HomeIcon className="w-6 h-6" />
          </button>
          <h1 className="text-base sm:text-lg font-bold">파트5 학습&교육 템플릿 문장20</h1>
          <div className="w-6"></div> {/* Placeholder div to ensure space balance */}
        </div>
        {/* Question Number */}
        <p className="text-base mb-6">Question Number: {parseInt(questionId.split('_').pop(), 10)}</p> {/* Increased margin */}
        {/* Instruction */}
        <p className="text-lg mb-6 text-left w-full">다음의 문장을 영어로 말해보세요:</p> {/* Increased margin */}
        {/* Korean Sentence Box */}
        {question ? (
          <div className="bg-white border-2 p-4 rounded-lg w-full mb-6"> {/* Increased bottom margin */}
            <p className="text-lg mb-4">{question.question}</p>
          </div>
        ) : (
          <p>Loading question...</p>
        )}
        {/* Start/Stop Recording Button */}
        {recording ? (
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-lg mb-6 w-full sm:w-auto"
            onClick={handleStopRecording}
          >
            Stop Recording
          </button>
        ) : (
          <button
            className="bg-[#009963] text-white px-4 py-2 rounded-lg mb-6 w-full sm:w-auto"
            onClick={handleStartRecording}
          >
            Start Recording
          </button>
        )}
        {/* Feedback Box */}
        {userAnswer && (
          <div className="mt-8"> {/* Increased margin */}
            <div className="flex justify-between border-b-2 border-[#E5E8EB] mb-4"> {/* Increased bottom margin */}
              <div
                className={`cursor-pointer text-lg w-1/3 text-center ${activeTab === 'transcription' ? 'border-b-4 border-[#009963] text-black' : 'border-none'}`}
                onClick={() => handleTabSwitch('transcription')}
              >
                나의 답변
              </div>
              <div
                className={`cursor-pointer text-lg w-1/3 text-center flex items-center justify-center ${activeTab === 'aiFeedback' ? 'border-b-4 border-[#009963] text-black' : 'border-none'}`}
                onClick={() => handleTabSwitch('aiFeedback')}
              >
                AI 피드백
                <SparklesIcon className="w-5 h-5 ml-2 text-yellow-500" />
              </div>
              <div
                className={`cursor-pointer text-lg w-1/3 text-center ${activeTab === 'modelAnswer' ? 'border-b-4 border-[#009963] text-black' : 'border-none'}`}
                onClick={() => handleTabSwitch('modelAnswer')}
              >
                모범답안
              </div>
            </div>
            {/* Tab Contents */}
            <div className="mt-4 p-4 bg-white rounded-lg w-full h-48 overflow-y-auto">
              {activeTab === 'transcription' && <p>{userAnswer}</p>}
              {activeTab === 'aiFeedback' && <p>{feedback}</p>}
              {activeTab === 'modelAnswer' && (
                <p>{question ? question.answer : 'Loading model answer...'}</p>
              )}
            </div>
            {/* Retry and Next Buttons */}
            <div className="flex space-x-4 mt-8"> {/* Increased margin */}
              <button
                className="bg-[#F5F0E5] text-black px-4 py-2 rounded-lg"
                onClick={handleRetry}
              >
                Retry
              </button>
              <button
                className="bg-[#009963] text-white px-4 py-2 rounded-lg"
                onClick={handleNextQuestion}
              >
                Next Question
              </button>
            </div>
          </div>
        )}
        {/* Skip and Previous Buttons */}
    {!feedback && (
      <div className="w-full max-w-md sm:max-w-lg lg:max-w-xl mt-4 mb-4"> {/* Added margin for bottom */}
        <div className="flex justify-between space-x-4">
          <button
            className="bg-[#F5F0E5] text-black px-4 py-2 rounded-lg w-full"
            onClick={() => {
              const previousQuestionId = determinePreviousQuestionId();
              setQuestionId(previousQuestionId);
              fetchQuestion(previousQuestionId);
            }}
          >
            Previous Question
          </button>
          {(hasSubscription || questionId !== 'q3_t_learning_01') && (
            <button
              className="bg-[#F5F0E5] text-black px-4 py-2 rounded-lg w-full"
              onClick={() => {
                const nextQuestionId = determineNextQuestionId();
                setQuestionId(nextQuestionId);
                fetchQuestion(nextQuestionId);
              }}
            >
              Skip Question
            </button>
          )}
        </div>
      </div>
    )}
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-md">
            <h2 className="text-xl font-bold mb-4">구독이 필요합니다</h2>
            <p className="mb-4">더 많은 문제를 풀어보시려면 구독해주세요.</p>
            <button
              onClick={() => navigate('/pricing')}
              className="w-full bg-[#3488fe] text-white py-2 rounded-lg"
            >
              구독하기
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Part5TemplatePracticeLearning;