import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useAuth } from '../context/AuthProvider';  // Use AuthProvider context
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const SignUpPage = () => {
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [daysUntilExam, setDaysUntilExam] = useState('');
  const [examPurpose, setExamPurpose] = useState('');
  const [error, setError] = useState('');
  
  // Remove these from page: we'll show them in a modal
  // const [termsAccepted, setTermsAccepted] = useState(false);
  // const [privacyAccepted, setPrivacyAccepted] = useState(false);

  // Terms and privacy content
  const [termsContent, setTermsContent] = useState('');
  const [privacyContent, setPrivacyContent] = useState('');

  // New states for modal
  const [showAgreements, setShowAgreements] = useState(false);
  const [modalTermsAccepted, setModalTermsAccepted] = useState(false);
  const [modalPrivacyAccepted, setModalPrivacyAccepted] = useState(false);

  const navigate = useNavigate();
  const { loginAction } = useAuth();  // Use loginAction to store token and user info
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Load terms and privacy policy content
    fetch('/service.txt')
      .then(response => response.text())
      .then(text => setTermsContent(text))
      .catch(err => console.error('Failed to load terms:', err));

    fetch('/privacy.txt')
      .then(response => response.text())
      .then(text => setPrivacyContent(text))
      .catch(err => console.error('Failed to load privacy policy:', err));
  }, []);

  // The actual sign-up function (same as before, but we removed the checkbox checks from here)
  const handleSignUp = async () => {
    // Validate passwords
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/api/signup`, {
        full_name: fullName,
        user_id: username,
        password,
        days_until_exam: daysUntilExam,
        exam_purpose: examPurpose,
      });

      if (response.data.status === 'success') {
        mixpanel.track('Sign Up', { user_id: username });
        // Call loginAction to store the token and user information
        await loginAction({ user_id: username, password });
        navigate('/');  // Navigate to the landing page after sign-up
      } else {
        setError('Sign-up failed. Please try again.');
      }
    } catch (err) {
      console.error('Error during sign-up:', err);
      setError('아래 필수 항목을 모두 입력해주세요.');
    }
  };

  // Handler for "토익스피킹 부수기" button - opens modal instead of immediate sign up
  const handlePreSignUp = () => {
    // Clear any prior error
    setError('');
    // Open the modal
    setShowAgreements(true);
  }

  // Handler when user clicks "동의하고 가입하기" inside the modal
  const handleConfirmAgreements = () => {
    // Check if both are accepted
    if (!modalTermsAccepted || !modalPrivacyAccepted) {
      // Could show an error message at the modal, or do something else
      alert('서비스 약관과 개인정보 수집에 모두 동의해야 가입이 가능합니다.');
      return;
    }

    // If accepted, close the modal, then proceed with signup
    setShowAgreements(false);
    handleSignUp();
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      {/* Back Button */}
      <button className="absolute top-4 left-4 text-xl" onClick={() => navigate(-1)}>
        ←
      </button>

      <h1 className="text-3xl font-bold mb-4">Create your account</h1>

      {/* Error message */}
      {error && <p className="text-red-500">{error}</p>}

      <div className="w-80 md:w-[600px]"> {/* Container for form elements */}
        {/* Full Name Input */}
        <input
          className="mb-2 p-4 w-full bg-[#f9fafc] border border-gray-200 rounded-lg text-gray-700 placeholder-gray-500"
          type="text"
          placeholder="이름"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />

        {/* Username Input */}
        <input
          className="mb-2 p-4 w-full bg-[#f9fafc] border border-gray-200 rounded-lg text-gray-700 placeholder-gray-500"
          type="email"
          placeholder="이메일"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        {/* Password Input */}
        <input
          className="mb-2 p-4 w-full bg-[#f9fafc] border border-gray-200 rounded-lg text-gray-700 placeholder-gray-500"
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {/* Confirm Password Input */}
        <input
          className="mb-2 p-4 w-full bg-[#f9fafc] border border-gray-200 rounded-lg text-gray-700 placeholder-gray-500"
          type="password"
          placeholder="비밀번호 재입력"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        {/* Days Left Until Exam Dropdown */}
        <Menu as="div" className="relative inline-block text-left mb-2 w-full">
          <div>
            <MenuButton className="inline-flex w-full justify-between rounded-lg bg-[#f9fafc] border border-gray-200 px-4 py-4 text-gray-700 shadow-sm hover:bg-gray-50">
              {daysUntilExam || '시험까지 몇 일 남았나요?'}
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
            </MenuButton>
          </div>
          <MenuItems className="absolute z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-48 overflow-y-auto">
            <div className="py-1">
              {[...Array(30).keys()].map((day) => (
                <MenuItem key={day + 1}>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                      } block w-full px-4 py-2 text-sm`}
                      onClick={() => setDaysUntilExam(day + 1)}
                    >
                      {day + 1} 일
                    </button>
                  )}
                </MenuItem>
              ))}
            </div>
          </MenuItems>
        </Menu>

        {/* Purpose of Exam Dropdown */}
        <Menu as="div" className="relative inline-block text-left mb-2 w-full">
          <div>
            <MenuButton className="inline-flex w-full justify-between rounded-lg bg-[#f9fafc] border border-gray-200 px-4 py-4 text-gray-700 shadow-sm hover:bg-gray-50">
              {examPurpose || '토익스피킹 응시목적은 무엇인가요?'}
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
            </MenuButton>
          </div>
          <MenuItems className="absolute z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-48 overflow-y-auto">
            <div className="py-1">
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    } block w-full px-4 py-2 text-sm`}
                    onClick={() => setExamPurpose('승진')}
                  >
                    승진
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    } block w-full px-4 py-2 text-sm`}
                    onClick={() => setExamPurpose('취업/이직')}
                  >
                    취업/이직
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    } block w-full px-4 py-2 text-sm`}
                    onClick={() => setExamPurpose('졸업/학점인정')}
                  >
                    졸업/학점인정
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    } block w-full px-4 py-2 text-sm`}
                    onClick={() => setExamPurpose('연수성과측정')}
                  >
                    연수성과측정
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    } block w-full px-4 py-2 text-sm`}
                    onClick={() => setExamPurpose('영어공부')}
                  >
                    영어공부
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    } block w-full px-4 py-2 text-sm`}
                    onClick={() => setExamPurpose('기타')}
                  >
                    기타
                  </button>
                )}
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>

        {/* Removed the inline terms and privacy checkboxes here */}

        {/* Sign Up Button -> triggers modal instead */}
        <button
          className="w-full px-4 py-4 mt-4 bg-[#3488fe] text-white rounded-lg font-bold hover:bg-[#2670e8]"
          onClick={handlePreSignUp}
        >
          토익스피킹 부수기
        </button>
      </div>

      {/* Modal for agreements */}
      {showAgreements && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          {/* Modal container */}
          <div className="bg-white w-[90%] md:w-[600px] p-6 rounded-lg relative">
            <h2 className="text-lg font-bold mb-4">서비스 이용 약관 및 개인정보 수집 동의</h2>

            <div className="mb-4">
              <h3 className="text-sm font-bold mb-2">서비스 이용 약관</h3>
              <div className="h-24 bg-[#f9fafc] overflow-y-auto border p-2 rounded">
                <p className="text-xs whitespace-pre-wrap">{termsContent}</p>
              </div>
              <label className="mt-2 flex items-center text-sm">
                <input
                  type="checkbox"
                  checked={modalTermsAccepted}
                  onChange={() => setModalTermsAccepted(prev => !prev)}
                  className="mr-2"
                />
                서비스 이용 약관에 동의합니다
              </label>
            </div>

            <div className="mb-4">
              <h3 className="text-sm font-bold mb-2">개인정보 수집 동의</h3>
              <div className="h-24 bg-[#f9fafc] overflow-y-auto border p-2 rounded">
                <p className="text-xs whitespace-pre-wrap">{privacyContent}</p>
              </div>
              <label className="mt-2 flex items-center text-sm">
                <input
                  type="checkbox"
                  checked={modalPrivacyAccepted}
                  onChange={() => setModalPrivacyAccepted(prev => !prev)}
                  className="mr-2"
                />
                개인정보 수집에 동의합니다
              </label>
            </div>

            {/* Buttons */}
            <div className="flex justify-end space-x-2">
              <button
                className="px-4 py-2 rounded-lg bg-gray-200 text-gray-700 hover:bg-gray-300"
                onClick={() => setShowAgreements(false)}  // Cancel / close modal
              >
                취소
              </button>
              <button
                className={`px-4 py-2 rounded-lg text-white font-bold ${
                  modalTermsAccepted && modalPrivacyAccepted
                    ? 'bg-[#3488fe] hover:bg-[#2670e8]'
                    : 'bg-gray-400 cursor-not-allowed'
                }`}
                onClick={handleConfirmAgreements}
                disabled={!modalTermsAccepted || !modalPrivacyAccepted}
              >
                동의하고 가입하기
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUpPage;